<script>
import SessionCard from "@/components/Sessions/SessionCard";

export default {
  components: {SessionCard}
}
</script>
<template>
  <div>
    <v-dialog v-model="dialog" persistent width="500px" style="border-radius: 19px">
      <v-card height="500px" max-height="" style="border-radius: 19px" class="d-flex flex-column justify-center">
        <div class="wellcomeBox ">
          <div class="text-center mb-3">
            <img src="../../assets/image/svg/TIK.svg" alt="">
          </div>
          <div class="titleBox text-center">
            {{ $t('sessions.modal.title') }}
          </div>
          <div class="contentBox mt-10 ">
            {{ $t('sessions.modal.subtitle') }}
          </div>
          <div class="actionBox text-center mt-12">
            <v-btn class="confirmBtn" @click="dialog=false" elevation="0">
              {{ $t('sessions.modal.action') }}
            </v-btn>
          </div>
        </div>
        <img src="../../assets/image/svg/Right_up.svg" class="RightTop" alt="">
        <img src="../../assets/image/svg/Left_down.svg" class="LeftDown" alt="">
      </v-card>
    </v-dialog>
    <div class="SessionRequestDialog">
      <v-dialog v-model="SessionRequestModal" scrollable width="800px" style="">
        <div style="position: relative">
          <div class="circleOne"></div>
          <div class="circleTwo"></div>
          <v-card v-if="state == 'need_to_buy'" style="overflow: hidden">
            <v-card-text>
              <div style=";position: relative;z-index: 3">
                <div class="text-center">
                  <img src="../../assets/EmptyState.svg" width="200" alt="">
                </div>
                <div class="titleSessionRequestBlack text-center" style="margin-top: 0px">
                  درحال حاضر جلسه خریداری شده ندارید.
                </div>
                <div class="text-center mt-8">
                  <v-btn color="#3CBE8B" :to="{name:'packages'}" x-small @click="sessionWindow = 2" large elevation="0"
                         class="white--text font-weight-bold">
                    خرید جلسه
                  </v-btn>
                </div>
              </div>
            </v-card-text>
          </v-card>
          <v-card v-else-if="state == 'need_doctor'">
            <v-card-text>
              <div style="position: relative;z-index: 3">
                <div class="text-center">
                  <img src="../../assets/image/svg/messanger/noPhotoClient.svg" width="100" class="my-4" alt="">
                </div>
                <div class="titleSessionRequestBlack text-center" style="margin-top: 0px">
                  لطفا تا برگزاری جلسه پیش‌مشاوره و مشخص شدن مشاوره خود صبر کنید.
                </div>
                <div class="text-center mt-8">
                  <a href="https://wa.me/989302672054" target="_blank">
                    <v-btn color="#3CBE8B" x-small large elevation="0"
                           class="white--text font-weight-bold">
                      ارتباط با پشتیبانی
                    </v-btn>
                  </a>
                </div>
              </div>
            </v-card-text>
          </v-card>
          <v-window v-model="sessionWindow" touchless v-if="state == 'can_send_request'">
            <v-window-item :value="1">
              <v-card class="pa-15">
                <div class="sessionRequestInfo" style="position: relative;z-index: 3">
                  <div class="doctorImage text-center" style="">
                    <img :src="doctor.avatar || require('../../assets/gender/OTHER.svg')" width="100%" alt="">
                  </div>
                  <div class="titleSessionRequest text-center">
                    درخواست جلسه
                  </div>
                  <div class="textSessionRequest">
                    شما در حال ارسال درخواست جلسه برای دکتر
                    <span class="doctorName">
                                  {{ doctor.full_name }}
                                </span>
                    هستید.
                  </div>
                  <div class="actionSessionRequestInfo text-center mt-10">
                    <v-btn color="#3CBE8B" @click="sendSessionRequest" large elevation="0"
                           class="white--text font-weight-bold">
                      درخواست جلسه
                    </v-btn>
                    <br>
                    <a href="https://wa.me/989302672054" target="_blank">
                      <v-btn class="mt-2" small text color="#3CBE8B">
                        درخواست تغیر مشاور
                      </v-btn>
                    </a>
                  </div>
                </div>
              </v-card>
            </v-window-item>
            <v-window-item :value="2">
              <v-card>
                <v-card-text class="text-center">
                  <div style="position: relative;z-index: 3">
                            <span class="text-center font-weight-bold" style="position: relative;z-index: 12">
                               زمان‌های خالی دکتر
                        {{ doctor.full_name }}
                          </span>
                    <SelectDateTimeSession v-if="loadCalendar && sessionWindow == 2" @noTimeHandler="noTimeHandler"
                                           @closeHandler="closeSessionRequestModal"
                                           @nextHandler="notifyUserSessionSet"
                                           :user="profile"/>
                    <div class="text-center">
                      <a href="https://wa.me/989302672054" target="_blank">
                        <v-btn class="mt-2" small text color="#3CBE8B">
                          درخواست تغیر مشاور
                        </v-btn>
                      </a>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-window-item>
            <v-window-item :value="3">
              <v-card class="pa-4">
                <div style="position: relative;z-index: 3;height: 600px;overflow: auto">
                  <AddToCalendar @nextHandler="afterAddToCalender" class="text-center" actionText="بستن"/>
                </div>
              </v-card>
            </v-window-item>
            <v-window-item :value="4">
              <div class="pa-5 white text-center" style="">
                <div style="z-index: 999;position: relative">
                  <v-icon x-large color="green" style="border: 1px solid green;border-radius: 50%;padding: 15px">
                    mdi-check
                  </v-icon>
                  <br>
                  <div class="mt-5 font-weight-bold">
                    درخواست جلسه شما ثبت شد
                  </div>
                  <p style="line-height: 25pt">
                    درخواست جلسه شما ثبت شد. تیم پشتیبانی برای هماهنگی زمان جلسه با شما تماس میگیرد.
                  </p>
                  <v-btn color="#3CBE8B" @click="closeSuccessNotify" large elevation="0"
                         class="white--text font-weight-bold">
                    بستن
                  </v-btn>
                </div>
              </div>
            </v-window-item>
          </v-window>
        </div>
      </v-dialog>

    </div>
    <v-row class="pa-0" justify="center">
      <v-dialog v-model="filter" :width="$vuetify.breakpoint.md ? '400px' : '500px'">
        <v-card class="white" style="border-radius: 10px">
          <SessionCalendar class=""/>
        </v-card>
      </v-dialog>
      <v-col cols="12" md="8" class="" lg="8">
        <div class="d-flex justify-space-between">
          <div class="titleProfileSection mt-5 mx-5">
            <v-icon small color="#4597DF">mdi-circle</v-icon>
            {{ $t('sessions.title') }}
            <div class="selected-text d-flex    mr-5" v-if="date">
              جلسات شما در تاریخ
              <span class="mr-2">
                {{ isToday(date) }}
              </span>

              <v-spacer></v-spacer>
              <v-icon small class="mr-2" color="#4597DF" @click="resetFilter">
                mdi-close
              </v-icon>
            </div>
          </div>

          <div>
            <v-btn class="SessionRequestBtn white--text" elevation="0" @click="openSessionRequestModal"
                   v-if="$vuetify.breakpoint.mdAndUp">
              {{ $t('sessions.session_request') }}
            </v-btn>
            <template v-else>
              <v-bottom-navigation fixed>
                <v-btn class="SessionRequestBtnMob white--text " block elevation="0" @click="openSessionRequestModal">
                  <span class="white--text" style="font-size: 14px">
                                      {{ $t('sessions.session_request') }}
                  </span>
                </v-btn>
              </v-bottom-navigation>
            </template>
          </div>
        </div>
        <v-fade-transition mode="out-in">
          <div :key="1" v-if="localLoader">
            <div class="emptyState text-center mt-10" key="2">
              <img src="../../assets/image/svg/empty_state.svg" width="250px">
              <p>
                {{ $t('sessions.loading') }}
              </p>
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>
          </div>
          <div :key="2" v-else>
            <v-fade-transition mode="out-in">
              <div v-if=" sessions.length != 0" key="1">
                <v-fade-transition mode="out-in">
                  <div :key="423">
                    <div v-for="(session , i) in sessions" :key="i">
                      <SessionCard @getAllSession="testing"
                                   :class="$vuetify.breakpoint.mdAndUp ? 'px-10' : 'px-5'" :session="session"
                                   :key="`${i}_session`"/>
                    </div>
                  </div>
                </v-fade-transition>
              </div>
              <div class="emptyState text-center mt-10" v-else key="2">
                <img src="../../assets/image/svg/empty_state.svg" width="250px">
                <p>
                  {{ $t('sessions.empty') }}
                </p>
                <!--                <v-btn color="#1c53c1" text :to="{name:'packages',query:{step:'reserve'}}">-->
                <!--                  {{ $t('sessions.action') }}-->
                <!--                </v-btn>-->
              </div>
            </v-fade-transition>

          </div>
        </v-fade-transition>
      </v-col>
      <!--      <v-col cols="12" md="4" lg="4" v-if="$vuetify.breakpoint.mdAndUp">-->
      <!--        <SessionCalendar class="mt-5"/>-->
      <!--      </v-col>-->
    </v-row>
  </div>
</template>

<script>

// import SessionCard from "@/components/Session/SessionCard";
import moment from "moment-jalaali";
import SessionCard from "@/components/Sessions/SessionCard";
import SessionCalendar from "@/components/Dashboard/SessionCalendar";
import {mapGetters} from "vuex";
import SessionMixins from "@/mixins/SessionMixins";
import SelectDateTimeSession from "@/components/OnBoarding/New/SelectDateTimeSession";
import AddToCalendar from "@/components/OnBoarding/New/AddToCalendar";
/*eslint-disable*/
export default {
  components: {AddToCalendar, SelectDateTimeSession, SessionCalendar, SessionCard},
  mixins: [SessionMixins],
  data() {
    return {
      dialog: false,
      localLoader: false,
      filter: false,
      date: new Date(),
      SessionRequestModal: false,
      sessionWindow: 1,
      sessions: [],
      state: null,
      loadCalendar: true,
      paginationLock: false,
      bindListner: false,
      pagination: {
        total_pages: 1,
        current_page: 1,
      }
    }
  },
  computed: {
    ...mapGetters({
      profile: 'getProfile',
      // doctor: 'getDoctor',
    }),
    doctor() {
      return this.profile.assignedDoctor
    },
    canSendSessionRequest() {
      return this.profile.assignedDoctor.length == 0
    }
  },
  methods: {
    closeSuccessNotify(){
      this.$router.push({name: "sessions"})
    },
    sendSessionRequest() {
      this.$emitEvent('setLoading', true)

      this.requesting('session', 'sendSessionRequest', null, {
        cycle_id: this.profile.cycles[0].id,
        session_count: 1
      }).then(() => {
        this.getAllSession(1)
        this.sessionWindow = 4

      }).finally(() => {
        this.$emitEvent('setLoading', false)
      })
    },
    testing() {
      // this.getSessions()
      this.resetFilter()
    },

    resetSelectTimeModal() {
      this.sessionWindow = 1
      this.reloadCalender()
    },
    noTimeHandler() {
      this.$router.push({name: "sessions"})
      this.$nextTick().then(() => {
        this.$emitEvent('notify', {
          title: "جلسه شما رزرو شد اما مشاور شما در این لحظه زمان خالی ندارد",
          color: 'green'
        })
        this.date = null
        this.reloadCalender()
        this.getAllSession(1)
      })
    },
    afterAddToCalender() {
      this.$router.push({name: "sessions"})
      this.date = null
      this.getAllSession(1)
    },
    closeSessionRequestModal() {
      this.$router.push({name: "sessions"})
    },
    reloadCalender() {
      this.loadCalendar = false
      // this.$nextTick().then(() => {
      //   this.loadCalendar = true
      // })
    },
    notifyUserSessionSet() {
      this.$emitEvent('setLoading', false)
      // this.SessionRequestModal = false
      this.reloadCalender()
      this.sessionWindow = 3
      // this.$nextTick().then(() => {
      //   this.$emitEvent('notify', {title: this.$t('Notify.session_request'), color: 'green'})
      // })

    },
    isToday(val) {
      let today = moment(new Date()).format("jYYYY/jMM/jDD")
      let date = moment(this.date).format("jYYYY/jMM/jDD")
      return today == date ? this.$t('today') : this.jalaliToGregorian(val, 'YYYY/MM/DD') + "  -  " + val
    },
    openSessionRequestModal(redirect = true) {
      if (redirect) {
        this.$router.push({name: "sessions-request"})
      }
      this.sessionWindow = 1

      if (this.profile.state.requireBuy) {
        this.state = 'need_to_buy'
        this.SessionRequestModal = true
        // this.$emitEvent('notify', {title: "تعداد بسته‌های شما کافی نیست. ابتدا خرید کنید", color: 'red'})
        // setTimeout(() => {
        //   this.$router.push({name: "packages"})
        // }, 1000)
      } else if (this.profile.assignedDoctor.length == 0) {
        this.state = 'need_doctor'
        this.SessionRequestModal = true
      } else {
        if (this.profile.cycles[0]) {
          this.state = 'can_send_request'
          this.loadCalendar = true
          this.SessionRequestModal = true
        } else {
          this.$emitEvent('notify', {title: this.$t('Notify.without_cycle'), color: 'red'})
          this.$router.push({name: "onboarding_new"})
        }
      }
    },
    getSessions(from_date = null, to_end_of_day = null) {
      this.localLoader = true
      this.date = moment(from_date ?? new Date()).format("jYYYY/jMM/jDD")
      let payload = {
        orderBy: 'session_date:asc',
        from_date: moment(from_date ?? new Date()).format("y-M-D"),
        to_end_of_day: moment(to_end_of_day ?? new Date()).format("y-M-D"),
      }
      this.$emitEvent('setLoading', true)
      this.requesting('session', 'getSessionsForDay', '', payload).then((resp) => {
        this.localLoader = false
        this.sessions = resp.data.sessions
      }).finally(() => {
        this.$emitEvent('setLoading', false)
      })
    },

    getSessionsWithOutUpdateStore(from_date = null, to_end_of_day = null) {
      this.localLoader = true
      this.date = moment(from_date ?? new Date()).format("jYYYY/jMM/jDD")
      let payload = {
        orderBy: 'session_date:asc',
        from_date: moment(from_date ?? new Date()).format("y-M-D"),
        to_end_of_day: moment(to_end_of_day ?? new Date()).format("y-M-D"),
      }
      this.$emitEvent('setLoading', true)
      this.requesting('session', 'getSessionsWithOutUpdateStoreForDay', '', payload).then((resp) => {
        this.localLoader = false
        this.sessions = resp.data.sessions
      }).finally(() => {
        this.$emitEvent('setLoading', false)
      })
    },
    getAllSession(page, callback = () => {
    }) {
      // this.localLoader = true
      let payload = {
        orderBy: 'session_date:asc',
      }
      this.$emitEvent('setLoading', true)
      if (!this.paginationLock) {
        this.paginationLock = true
        this.requesting('session', 'getSessionsForDay', {page}, payload).then((resp) => {
          // this.localLoader = false
          if (page == 1) {
            this.sessions = resp.data.sessions
          } else {
            this.sessions.push(...resp.data.sessions)
          }
          this.pagination = resp.data.pagination
          if (!this.bindListener) {
            this.bindingListner()
          }
        }).finally(() => {
          this.$emitEvent('setLoading', false)
          this.paginationLock = false
          this.$nextTick().then(() => {
            // callback()
          })
        })
      }

    },
    resetFilter() {
      this.date = null
      if (this.$route.name != 'sessions') {
        this.$router.push({name: "sessions"})
      }
      this.getAllSession(1)
    },
    handlerListener() {
      this.bindListner = true
      setTimeout(() => {
        const scrollValue = window.scrollY;

        const distanceToBottom = document.documentElement.scrollHeight - window.innerHeight - scrollValue;
        if (Math.floor(distanceToBottom) <= 0) {
          if (this.pagination.current_page != this.pagination.total_pages) {
            this.getAllSession(this.pagination.current_page + 1, () => {
              setTimeout(() => {
                window.scrollTo({top: window.innerHeight});
              }, 500)
            })
          }
        }
      }, 500)
    },
    bindingListner() {
      window.addEventListener('scroll', this.handlerListener);
    },
    openModalSessionRequest() {
      if (this.$route.name == 'sessions-request') {
        setTimeout(() => {
          this.openSessionRequestModal(false)
        }, 500)
      } else {
        this.SessionRequestModal = false
      }
    }
  },
  mounted() {
    this.$onEvent('FilterModalTrigger', (val) => {
      this.filter = val
    })
    // this.getSessions()


    // Add the scroll event listener to the window object


    // if (this.$route.query.date) {
    //   this.getSessions(this.$route.query.date, this.$route.query.date)
    // } else {
    this.date = null
    this.getAllSession(1)
    // }
    this.$onEvent('changeSessionPeriod', (val) => {
      this.getSessionsWithOutUpdateStore(val, val)
    })


    this.openModalSessionRequest()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handlerListener);
  },
  filters: {},
  watch: {
    // '$route.query.date'(){
    //  console.log('from me')
    //   this.getSessions(this.$route.query.date, this.$route.query.date)
    // }
    '$route'() {
      this.openModalSessionRequest()
    }


  }
}
</script>

<style>

@media (min-width: 200px) and (max-width: 600px) {
  .selected-text {
    font-size: 10px !important;
  }
}

.v-dialog {
  overflow: hidden !important;
}

.circleOne {
  position: absolute;
  left: -120px;
  top: -40px;
  width: 250px;
  height: 250px;
  -webkit-filter: blur(10px);
  filter: blur(10px);
  background: #e9fbff;
  border-radius: 50%;
  z-index: 2;
}

.circleTwo {
  position: absolute;
  right: -120px;
  bottom: -60px;
  width: 250px;
  height: 250px;
  -webkit-filter: blur(10px);
  filter: blur(10px);
  background: #eafffa;
  border-radius: 50%;
  z-index: 2;
}


.sideOne {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100px;
  z-index: 1;
}

.sideTwo {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100px;
  z-index: 1;

}

.textSessionRequest {
  font-size: 15px;
  text-align: center;
  margin-top: 20px;
  /*font-weight: lighter;*/
}

.titleSessionRequest {
  color: #1c53c1;
  font-size: 20px;
  margin-top: 20px;
}

.titleSessionRequestBlack {
  color: #333333;
  font-size: 18px;
  margin-top: 20px;
}

.doctorName {
  color: #1c53c1;
  font-weight: bolder;
}

.doctorImage {
  background: white;

  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0px auto;
}

.headerSessionRequestModal {
  background: #3CBE8B;
}

.selected-text {
  color: #4597DF;
  font-size: 14px;
  border-radius: 10px;
  padding: 2px 5px;
  text-align: right;
  border: 1px solid #4597DF;
}

.titlePage {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  /* identical to box height, or 133% */
  text-align: right;
  font-feature-settings: 'tnum' on, 'lnum' on;

  /* label parimary */
  color: #434343;
  margin: 10px 0px;
}

.SessionRequestBtnMob {
  background: #3CBE8B !important;
  width: 100% !important;
  height: 100% !important;
  color: white !important;
}

.SessionRequestBtn {
  width: 197px;
  height: 48px;
  background: #3CBE8B !important;
  border-radius: 10px;
  margin-top: 20px;
  color: white;
}

.wellcomeBox {
  padding: 20px;
}

.titleBox {
  color: #4597DF;
  font-size: 20px;
}

.contentBox {
  font-size: 18px;
  text-align: center;
  line-height: 24pt;
  width: 95%;
  margin: 0px auto;
}

.RightTop {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 130px;
}

.LeftDown {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 130px;
}

.wellcomeBox {
  position: relative;
  z-index: 50;
}

.confirmBtn {
  background: #3CBE8B !important;
  border-radius: 9px;
  width: 260px !important;
  height: 50px !important;
  color: white;
  font-size: 16px;
}
</style>